const $ = require('jquery')
const Highcharts = require('highcharts')
const _ = require('lodash')
const moment = require('moment')
const Hammer = require('hammerjs')
const PullToRefresh = require('pulltorefreshjs')

// Note:
// Webpack seems to eval default export differently compared to node.js.
// In webpack: object with `default` own property
// In nodejs: plain fct
const SimpleBar = require('simplebar').default

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/boost')(Highcharts)
require('highcharts/modules/boost-canvas')(Highcharts)
require('highcharts/modules/drilldown')(Highcharts)
require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)
require('highcharts/modules/offline-exporting')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)
require('highcharts/modules/solid-gauge')(Highcharts)

module.exports = {
  $: $,
  jQuery: $,
  Highcharts: Highcharts,
  _: _,
  moment: moment,
  Hammer: Hammer,
  PullToRefresh: PullToRefresh
}

// export libs to global scope
// needed by 'legacy' JS code
for (const e in module.exports) {
  window[e] = module.exports[e]
}

try {
  moment.locale($('#main').data('user-datetime-locale') || 'en')
} catch (err) {
  console.error(err)
}

document.addEventListener('DOMContentLoaded', function () {
  try {
    // SimpleBar does only one thing: replace the browser's default scrollbar with a custom CSS-styled
    SimpleBar.initHtmlApi()
  } catch (err) {
    console.warn('SimpleBar initHtmlApi failed. Hint: check if required/imported propperly.')
    console.warn(err.message)
  }
})
